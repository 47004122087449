<template>
  <div class="about">
    <!-- Hero Section -->
    <section class="hero is-primary is-bold">
      <div class="hero-body">
        <div class="container has-text-centered">
          <h1 class="title is-size-1-touch">About the PFDAA</h1>
        </div>
      </div>
    </section>

    <!-- Content Section -->
    <section class="section">
      <div class="container">
        <div class="columns">
          <div class="column is-8 is-offset-2">
            <p class="org-description is-size-5-touch">
              The <strong>P.F.D.A.A. (Polish Folk Dance Association of the Americas, Inc.)</strong> was organized in 1983 to strengthen the network of Polish dance companies in the Western world. With over {{ ensembleCount }} ensembles across the USA and Canada, the PFDAA sponsors Polish folk dance festivals and serves as a hub for Polish folk music, dance, customs, and wardrobe.
              <br /><br />
              Our festivals combine dance classes, social activities, and gala concerts, making the PFDAA a pillar of Polish cultural preservation and promotion in North America.
            </p>

            <br />
            <div class="box">
              <h2 class="subtitle is-size-4-touch has-text-centered">Previous PFDAA Festivals</h2>
              <ul class="festival-list">
                <li class="list-item" v-for="festival in festivals" :key="festival.id">
                  {{ festival.year }} - {{ festival.name }}
                </li>
              </ul>
            </div>

            <p class="is-size-5-touch">
              For more information about the PFDAA or to inquire about membership for your ensemble, please email us at
              <a href="mailto:pfdaacontact@gmail.com">pfdaacontact@gmail.com</a>.
            </p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import FestivalService from "../services/festivalService";
import EnsembleService from "../services/ensembleService";

export default {
  name: "About",
  data() {
    return {
      festivals: [],
      ensembleCount: 0 // Initialize ensemble count
    };
  },
  async mounted() {
    this.festivals = await FestivalService.getAll();
    this.ensembleCount = await EnsembleService.getCount(); // Fetch ensemble count
  }
};
</script>

<style lang="scss" scoped>
.hero-body {
  background: linear-gradient(to right, #93291e, #ed213a);
}

.org-description {
  line-height: 1.8;
  margin-top: 20px;
  padding: 0 20px;
}

.box {
  background-color: #f9f9f9;
  border-radius: 10px;
  padding: 20px;
  margin-top: 30px;
}

.festival-list {
  list-style: none;
  padding: 0;
  font-size: 1.125rem;
  line-height: 1.5;
}

.list-item {
  margin-bottom: 10px;
  color: #333;
}

a {
  color: #3e8e41;
  text-decoration: none;
}

a:hover {
  color: #2c6e31;
  text-decoration: underline;
}

/* Responsive Styling */
@media screen and (max-width: 768px) {
  .hero-body {
    padding: 2rem 1.5rem;
  }

  .org-description {
    font-size: 1rem;
    padding: 0 15px;
  }

  .festival-list {
    font-size: 1rem;
  }
}
</style>
